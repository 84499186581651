import { SignatureDataWithDimensionsSchema } from '@careos/toxicology-types';
import { nonEmptyString, LocaleSchema } from '@careos/types';
import { z } from 'zod';

export const SignRequisitionPdfSchema = z.object({
  transactionId: nonEmptyString,
  signeeFullName: nonEmptyString,
  signeePersonalNumber: nonEmptyString,
  signature: SignatureDataWithDimensionsSchema,
  locale: LocaleSchema,
});

export type SignRequisitionPdfDto = z.infer<typeof SignRequisitionPdfSchema>;
