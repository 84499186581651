import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@careos/react-ui/Card';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCancelRequisitionRequest } from '@/features/order/form/api/cancel-requisition-request';
import { useHandSignRequisition } from '@/features/order/form/api/create-hand-sign-requisition';
import { RequisitionFormSign } from '@/features/order/form/components/requisition-form-sign';
import { ORDER_ERROR_MESSAGE } from '@/features/order/form/constants/errors';
import { getGenerateRequisitionRequestDto } from '@/features/order/form/helpers/get-generate-requisition-request-dto';
import { getSignatureDimensions } from '@/features/order/form/helpers/get-signature-dimensions';
import { useCollectionStore } from '@/features/order/form/stores/collection-store';
import { api } from '@/lib/api';
import { assertDefined } from '@/utils/assert-defined';

export const Route = createFileRoute(
  '/_authenticated/sessions/$sessionId/sign',
)({
  beforeLoad: ({ context: { collectionStore } }) => {
    return {
      donorInfoForm: assertDefined(
        collectionStore.donorInfoForm,
        ORDER_ERROR_MESSAGE.donorInfoFormUndefined,
      ),
      testInfoForm: assertDefined(
        collectionStore.testInfoForm,
        ORDER_ERROR_MESSAGE.testInfoFormUndefined,
      ),
    };
  },
  loader: async ({ context, params }) => {
    const dto = getGenerateRequisitionRequestDto({
      testInfo: context.testInfoForm,
      donorInfo: context.donorInfoForm,
      samplingInfo: context.collectionStore.samplingForm,
      sessionId: params.sessionId,
      user: context.user,
      locale: context.locale,
    });
    const generateRequisitionResponseData = await api.requisition.generate(dto);
    return {
      generateRequisitionResponseData,
      locale: context.locale,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });
  const { donorInfoForm } = Route.useRouteContext();
  const router = useRouter();
  const { mutate: cancelRequisitionRequestMutation } =
    useCancelRequisitionRequest();
  const updateSigning = useCollectionStore((state) => state.updateSigning);

  const navigate = Route.useNavigate();
  const { generateRequisitionResponseData } = Route.useLoaderData();

  const handleSuccessfulSigning = (transactionId: string) => {
    updateSigning({ transactionId });
    navigate({ to: '../signed' });
  };

  const handSignMutation = useHandSignRequisition({
    mutationConfig: {
      onSuccess: () => {
        handleSuccessfulSigning(generateRequisitionResponseData.transactionId);
      },
    },
  });

  const handleSubmitBankIdSignature = (transactionId: string) => {
    handleSuccessfulSigning(transactionId);
  };

  const handleSubmitSignature = async (signature: string) => {
    handSignMutation.mutate({
      transactionId: generateRequisitionResponseData.transactionId,
      signature: {
        dimensions: await getSignatureDimensions(signature),
        signatureData: signature,
        signatureType: 'HANDWRITTEN',
      },
    });
  };

  useEffect(() => {
    const unsubscribe = router.subscribe(
      'onBeforeNavigate',
      ({ toLocation }) => {
        if (toLocation.pathname.includes('/signed')) return;
        cancelRequisitionRequestMutation(
          generateRequisitionResponseData.transactionId,
        );
      },
    );
    return () => unsubscribe();
  }, [
    router,
    generateRequisitionResponseData.transactionId,
    cancelRequisitionRequestMutation,
  ]);

  return (
    <Card className="mx-auto mt-8 w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t('title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <RequisitionFormSign
          donorInfoForm={donorInfoForm}
          onSuccessfulSigning={handleSuccessfulSigning}
          onSubmitSignature={handleSubmitSignature}
          isSubmittingSignature={handSignMutation.isPending}
          onSubmitBankIdSignature={handleSubmitBankIdSignature}
          {...generateRequisitionResponseData}
        />
      </CardContent>
    </Card>
  );
}
