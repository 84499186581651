import { z } from 'zod';
import { DonorGetRequisitionByTransactionIdRequestDtoSchema } from './donor-get-requisition-by-transaction-id.request.dto';

export const BankIdSignRequestDtoSchema = z.object({
  transactionId: z.string().uuid(),
  returnUrl: z.string().optional(),
  userAgent: z.string().optional(),
});

export const DonorBankIdSignRequestDtoSchema = BankIdSignRequestDtoSchema.merge(
  DonorGetRequisitionByTransactionIdRequestDtoSchema,
);

export type BankIdSignRequestDto = z.infer<typeof BankIdSignRequestDtoSchema>;

export type DonorBankIdSignRequestDto = z.infer<
  typeof DonorBankIdSignRequestDtoSchema
>;

export const isBankIdSignRequest = (
  result: unknown,
): result is BankIdSignRequestDto =>
  BankIdSignRequestDtoSchema.strict().safeParse(result).success;

export const isDonorBankIdSignRequest = (
  result: unknown,
): result is DonorBankIdSignRequestDto =>
  DonorBankIdSignRequestDtoSchema.strict().safeParse(result).success;
