import { useMutation } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { MutationConfig } from '@/lib/react-query';

export const useInitiateRemoteSigning = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof api.requisition.initiateRemoteSigning>;
} = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: api.requisition.initiateRemoteSigning,
  });
};
