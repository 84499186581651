/**
 * A layout component that provides a background image and wraps its children.
 *
 * The background image is set to `/background.svg`, which **MUST** be located in the `/public` directory of the consumer app.
 *
 */

import { cn } from '#utils';

export const BackgroundLayout = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        `relative min-h-dvh bg-[url('/background.svg')] bg-cover bg-no-repeat`,
        className,
      )}
    >
      {children}
    </div>
  );
};
