import { useTranslation } from 'react-i18next';

export const OptionalLabelText = () => {
  const { t } = useTranslation();
  return (
    <span className="ml-1 align-text-top text-xs italic opacity-50">
      ({t('generic.form.optional')})
    </span>
  );
};
