import { z } from 'zod';
import { BankIdNativeCompletionDataSchema } from './bankid-native-completion-data';
import { BankIdStatusSchema } from './const/status';

export const BankIdNativeCollectResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: BankIdStatusSchema,
  hintCode: z.string().optional(),
  completionData: BankIdNativeCompletionDataSchema.optional(),
});

export type BankIdNativeCollectResponseDto = z.infer<
  typeof BankIdNativeCollectResponseDtoSchema
>;
