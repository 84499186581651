import { useMutation } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { MutationConfig } from '@/lib/react-query';

export const useSubmitRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof api.requisition.submit>;
} = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: api.requisition.submit,
  });
};
