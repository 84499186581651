import {
  Header as UIHeader,
  type HeaderProps as UIHeaderProps,
} from '@careos/react-ui/Header';

type HeaderProps = Omit<UIHeaderProps, 'menuItems'>;

export const Header = (props: HeaderProps) => {
  return <UIHeader {...props} />;
};
