import { isInvalidGrantError } from '#utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { Spinner } from '../Spinner';
import { Outlet } from '@tanstack/react-router';
import { UserContextProvider } from './UserContext/UserContextProvider';

const FIVE_MINUTES = 1000 * 60 * 5;
const NINETY_MINUTES = 1000 * 60 * 90;

export const AuthenticatedComponent = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLongLoadingTime, setIsLongLoadingTime] = useState(false);

  const fetchToken = useCallback(() => {
    getAccessTokenSilently()
      .then(() => setIsValidToken(true))
      .catch((e) => {
        setIsValidToken(false);
        setIsLongLoadingTime(isInvalidGrantError(e));
        loginWithRedirect({
          appState: { returnTo: window.location.pathname },
        });
      });
  }, [getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    if (!isValidToken) return;

    const intervalId = setInterval(fetchToken, FIVE_MINUTES);
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, NINETY_MINUTES);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [fetchToken, isValidToken]);

  if (!isValidToken) {
    return (
      isLongLoadingTime && (
        <div className="grid min-h-[calc(100dvh_-_58px)] place-content-center">
          <Spinner size="lg" />
        </div>
      )
    );
  }

  return (
    <UserContextProvider>
      <Outlet />
    </UserContextProvider>
  );
};
