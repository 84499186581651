import { z } from 'zod';

const ReasonsForTestingEnum = z.enum([
  'randomSelection',
  'newEmployee',
  'healthCheck',
  'rehabilitation',
  'incidentAccident',
]);
export type ReasonsForTesting = z.infer<typeof ReasonsForTestingEnum>;
export const ReasonsForTestingValues = ReasonsForTestingEnum.Values;

const RequisitionTTLSchema = z.object({
  TTLSec: z.number(),
});

const ResultTTLSchema = z.object({
  posTTLSec: z.number(),
  negTTLSec: z.number(),
});

export const RequisitionPolicySchema = z.object({
  randomSelection: RequisitionTTLSchema,
  newEmployee: RequisitionTTLSchema,
  incidentAccident: RequisitionTTLSchema,
  healthCheck: RequisitionTTLSchema,
  rehabilitation: RequisitionTTLSchema,
});

export const ResultPolicySchema = z.object({
  randomSelection: ResultTTLSchema,
  newEmployee: ResultTTLSchema,
  incidentAccident: ResultTTLSchema,
  healthCheck: ResultTTLSchema,
  rehabilitation: ResultTTLSchema,
});

export type RequisitionAccessControlPolicyDto = z.infer<
  typeof RequisitionPolicySchema
>;

export type ResultAccessControlPolicyDto = z.infer<typeof ResultPolicySchema>;

export type DefaultPolicy = {
  requisition: RequisitionAccessControlPolicyDto;
  result: ResultAccessControlPolicyDto;
};

const ONE_YEAR_IN_SECONDS = 31_536_000;
const ONE_MONTH_IN_SECONDS = 2_592_000;

export const workplaceDefaultPolicy: DefaultPolicy = {
  requisition: {
    randomSelection: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    newEmployee: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    incidentAccident: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    healthCheck: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    rehabilitation: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
  },
  result: {
    randomSelection: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_MONTH_IN_SECONDS,
    },
    newEmployee: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
    incidentAccident: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
    healthCheck: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
    rehabilitation: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
  },
};

export const treatmentCenterDefaultPolicy: DefaultPolicy = {
  requisition: {
    randomSelection: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    newEmployee: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    incidentAccident: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    healthCheck: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
    rehabilitation: {
      TTLSec: ONE_YEAR_IN_SECONDS,
    },
  },
  result: {
    randomSelection: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_MONTH_IN_SECONDS,
    },
    newEmployee: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
    incidentAccident: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
    healthCheck: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
    rehabilitation: {
      posTTLSec: ONE_YEAR_IN_SECONDS,
      negTTLSec: ONE_YEAR_IN_SECONDS,
    },
  },
};
