import { enforceExhaustive } from '@careos/react-ui/utils';
import type { BankIdSignRequestDto } from '@careos/toxicology-types';
import type {
  BankIdCheckResponseDto,
  BankIdRequestErrorResponseDto,
  BankIdSignResponseDto,
} from '@careos/types';
import { useRef } from 'react';

import { api } from '@/lib/api';

type FlowType = 'mobile' | 'qr';

export type PollFuncs = {
  onError: (error: BankIdRequestErrorResponseDto) => void;
  onUpdate: (args: BankIdCheckResponseDto) => void;
  onCompletion: (transactionId: string) => void;
};

export type BankIdInitiate = {
  flowType: FlowType;
  signArgs: BankIdSignRequestDto;
  onInitDone?: (args: BankIdSignResponseDto) => void;
} & PollFuncs;

export type BankIdPoll = {
  orderRef: string;
} & PollFuncs;

export const useBankIdPolling = () => {
  const getResultTimeout = useRef<NodeJS.Timeout>();
  const orderRef = useRef<string>();
  const inProgress = useRef<boolean>(false);

  const abort = () => {
    clearTimeout(getResultTimeout.current);
    if (inProgress.current && orderRef.current) {
      api.bankId.cancel(orderRef.current);
    }
  };

  const pollForResult = ({
    onUpdate,
    onError,
    onCompletion,
    orderRef,
  }: BankIdPoll) => {
    const getResult = () => {
      api.bankId
        .check(orderRef)
        .then((res) => {
          if ('errorCode' in res) {
            throw res;
          }

          // INFO: Not sure if this is necessary, but the BankID sample repo does it.
          if (orderRef && res.orderRef !== orderRef) {
            inProgress.current = false;
            abort();
            return;
          }

          onUpdate(res);

          const { status } = res;
          switch (status) {
            case 'pending': {
              getResultTimeout.current = setTimeout(getResult, 1000);
              break;
            }
            case 'complete': {
              inProgress.current = false;
              onCompletion(res.transactionId);
              break;
            }
            case 'failed': {
              inProgress.current = false;
              break;
            }
            default:
              enforceExhaustive(status);
          }
        })
        .catch(onError);
    };

    getResult();
  };

  const init = ({
    onInitDone,
    onUpdate,
    onCompletion,
    signArgs,
    onError,
  }: BankIdInitiate) => {
    api.bankId
      .sign(signArgs)
      .then((res) => {
        if ('errorCode' in res) {
          throw res;
        }

        if (onInitDone) {
          onInitDone(res);
        }

        orderRef.current = res.orderRef;
        inProgress.current = true;

        pollForResult({
          orderRef: res.orderRef,
          onUpdate,
          onCompletion,
          onError,
        });
      })
      .catch(onError);
  };

  return {
    init,
    pollForResult,
    abort,
  };
};
