/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as CallbackImport } from './routes/callback'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as DonorSuccessImport } from './routes/donor/success'
import { Route as AuthenticatedSessionsIndexImport } from './routes/_authenticated/sessions/index'
import { Route as DonorSigningTransactionIdImport } from './routes/donor/signing/$transactionId'
import { Route as AuthenticatedSessionsSessionIdRouteImport } from './routes/_authenticated/sessions/$sessionId/route'
import { Route as AuthenticatedSessionsSessionIdTestInfoImport } from './routes/_authenticated/sessions/$sessionId/test-info'
import { Route as AuthenticatedSessionsSessionIdSignedImport } from './routes/_authenticated/sessions/$sessionId/signed'
import { Route as AuthenticatedSessionsSessionIdSignImport } from './routes/_authenticated/sessions/$sessionId/sign'
import { Route as AuthenticatedSessionsSessionIdSamplingImport } from './routes/_authenticated/sessions/$sessionId/sampling'
import { Route as AuthenticatedSessionsSessionIdDonorInfoImport } from './routes/_authenticated/sessions/$sessionId/donor-info'
import { Route as AuthenticatedSessionsSessionIdCreatedImport } from './routes/_authenticated/sessions/$sessionId/created'

// Create/Update Routes

const CallbackRoute = CallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DonorSuccessRoute = DonorSuccessImport.update({
  id: '/donor/success',
  path: '/donor/success',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedSessionsIndexRoute = AuthenticatedSessionsIndexImport.update(
  {
    id: '/sessions/',
    path: '/sessions/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const DonorSigningTransactionIdRoute = DonorSigningTransactionIdImport.update({
  id: '/donor/signing/$transactionId',
  path: '/donor/signing/$transactionId',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedSessionsSessionIdRouteRoute =
  AuthenticatedSessionsSessionIdRouteImport.update({
    id: '/sessions/$sessionId',
    path: '/sessions/$sessionId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedSessionsSessionIdTestInfoRoute =
  AuthenticatedSessionsSessionIdTestInfoImport.update({
    id: '/test-info',
    path: '/test-info',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdSignedRoute =
  AuthenticatedSessionsSessionIdSignedImport.update({
    id: '/signed',
    path: '/signed',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdSignRoute =
  AuthenticatedSessionsSessionIdSignImport.update({
    id: '/sign',
    path: '/sign',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdSamplingRoute =
  AuthenticatedSessionsSessionIdSamplingImport.update({
    id: '/sampling',
    path: '/sampling',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdDonorInfoRoute =
  AuthenticatedSessionsSessionIdDonorInfoImport.update({
    id: '/donor-info',
    path: '/donor-info',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

const AuthenticatedSessionsSessionIdCreatedRoute =
  AuthenticatedSessionsSessionIdCreatedImport.update({
    id: '/created',
    path: '/created',
    getParentRoute: () => AuthenticatedSessionsSessionIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackImport
      parentRoute: typeof rootRoute
    }
    '/donor/success': {
      id: '/donor/success'
      path: '/donor/success'
      fullPath: '/donor/success'
      preLoaderRoute: typeof DonorSuccessImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/sessions/$sessionId': {
      id: '/_authenticated/sessions/$sessionId'
      path: '/sessions/$sessionId'
      fullPath: '/sessions/$sessionId'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdRouteImport
      parentRoute: typeof AuthenticatedImport
    }
    '/donor/signing/$transactionId': {
      id: '/donor/signing/$transactionId'
      path: '/donor/signing/$transactionId'
      fullPath: '/donor/signing/$transactionId'
      preLoaderRoute: typeof DonorSigningTransactionIdImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/sessions/': {
      id: '/_authenticated/sessions/'
      path: '/sessions'
      fullPath: '/sessions'
      preLoaderRoute: typeof AuthenticatedSessionsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/sessions/$sessionId/created': {
      id: '/_authenticated/sessions/$sessionId/created'
      path: '/created'
      fullPath: '/sessions/$sessionId/created'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdCreatedImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/donor-info': {
      id: '/_authenticated/sessions/$sessionId/donor-info'
      path: '/donor-info'
      fullPath: '/sessions/$sessionId/donor-info'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdDonorInfoImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/sampling': {
      id: '/_authenticated/sessions/$sessionId/sampling'
      path: '/sampling'
      fullPath: '/sessions/$sessionId/sampling'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdSamplingImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/sign': {
      id: '/_authenticated/sessions/$sessionId/sign'
      path: '/sign'
      fullPath: '/sessions/$sessionId/sign'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdSignImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/signed': {
      id: '/_authenticated/sessions/$sessionId/signed'
      path: '/signed'
      fullPath: '/sessions/$sessionId/signed'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdSignedImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
    '/_authenticated/sessions/$sessionId/test-info': {
      id: '/_authenticated/sessions/$sessionId/test-info'
      path: '/test-info'
      fullPath: '/sessions/$sessionId/test-info'
      preLoaderRoute: typeof AuthenticatedSessionsSessionIdTestInfoImport
      parentRoute: typeof AuthenticatedSessionsSessionIdRouteImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedSessionsSessionIdRouteRouteChildren {
  AuthenticatedSessionsSessionIdCreatedRoute: typeof AuthenticatedSessionsSessionIdCreatedRoute
  AuthenticatedSessionsSessionIdDonorInfoRoute: typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  AuthenticatedSessionsSessionIdSamplingRoute: typeof AuthenticatedSessionsSessionIdSamplingRoute
  AuthenticatedSessionsSessionIdSignRoute: typeof AuthenticatedSessionsSessionIdSignRoute
  AuthenticatedSessionsSessionIdSignedRoute: typeof AuthenticatedSessionsSessionIdSignedRoute
  AuthenticatedSessionsSessionIdTestInfoRoute: typeof AuthenticatedSessionsSessionIdTestInfoRoute
}

const AuthenticatedSessionsSessionIdRouteRouteChildren: AuthenticatedSessionsSessionIdRouteRouteChildren =
  {
    AuthenticatedSessionsSessionIdCreatedRoute:
      AuthenticatedSessionsSessionIdCreatedRoute,
    AuthenticatedSessionsSessionIdDonorInfoRoute:
      AuthenticatedSessionsSessionIdDonorInfoRoute,
    AuthenticatedSessionsSessionIdSamplingRoute:
      AuthenticatedSessionsSessionIdSamplingRoute,
    AuthenticatedSessionsSessionIdSignRoute:
      AuthenticatedSessionsSessionIdSignRoute,
    AuthenticatedSessionsSessionIdSignedRoute:
      AuthenticatedSessionsSessionIdSignedRoute,
    AuthenticatedSessionsSessionIdTestInfoRoute:
      AuthenticatedSessionsSessionIdTestInfoRoute,
  }

const AuthenticatedSessionsSessionIdRouteRouteWithChildren =
  AuthenticatedSessionsSessionIdRouteRoute._addFileChildren(
    AuthenticatedSessionsSessionIdRouteRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedSessionsSessionIdRouteRoute: typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  AuthenticatedSessionsIndexRoute: typeof AuthenticatedSessionsIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedSessionsSessionIdRouteRoute:
    AuthenticatedSessionsSessionIdRouteRouteWithChildren,
  AuthenticatedSessionsIndexRoute: AuthenticatedSessionsIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/donor/success': typeof DonorSuccessRoute
  '/sessions/$sessionId': typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  '/donor/signing/$transactionId': typeof DonorSigningTransactionIdRoute
  '/sessions': typeof AuthenticatedSessionsIndexRoute
  '/sessions/$sessionId/created': typeof AuthenticatedSessionsSessionIdCreatedRoute
  '/sessions/$sessionId/donor-info': typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  '/sessions/$sessionId/sampling': typeof AuthenticatedSessionsSessionIdSamplingRoute
  '/sessions/$sessionId/sign': typeof AuthenticatedSessionsSessionIdSignRoute
  '/sessions/$sessionId/signed': typeof AuthenticatedSessionsSessionIdSignedRoute
  '/sessions/$sessionId/test-info': typeof AuthenticatedSessionsSessionIdTestInfoRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/donor/success': typeof DonorSuccessRoute
  '/sessions/$sessionId': typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  '/donor/signing/$transactionId': typeof DonorSigningTransactionIdRoute
  '/sessions': typeof AuthenticatedSessionsIndexRoute
  '/sessions/$sessionId/created': typeof AuthenticatedSessionsSessionIdCreatedRoute
  '/sessions/$sessionId/donor-info': typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  '/sessions/$sessionId/sampling': typeof AuthenticatedSessionsSessionIdSamplingRoute
  '/sessions/$sessionId/sign': typeof AuthenticatedSessionsSessionIdSignRoute
  '/sessions/$sessionId/signed': typeof AuthenticatedSessionsSessionIdSignedRoute
  '/sessions/$sessionId/test-info': typeof AuthenticatedSessionsSessionIdTestInfoRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/donor/success': typeof DonorSuccessRoute
  '/_authenticated/sessions/$sessionId': typeof AuthenticatedSessionsSessionIdRouteRouteWithChildren
  '/donor/signing/$transactionId': typeof DonorSigningTransactionIdRoute
  '/_authenticated/sessions/': typeof AuthenticatedSessionsIndexRoute
  '/_authenticated/sessions/$sessionId/created': typeof AuthenticatedSessionsSessionIdCreatedRoute
  '/_authenticated/sessions/$sessionId/donor-info': typeof AuthenticatedSessionsSessionIdDonorInfoRoute
  '/_authenticated/sessions/$sessionId/sampling': typeof AuthenticatedSessionsSessionIdSamplingRoute
  '/_authenticated/sessions/$sessionId/sign': typeof AuthenticatedSessionsSessionIdSignRoute
  '/_authenticated/sessions/$sessionId/signed': typeof AuthenticatedSessionsSessionIdSignedRoute
  '/_authenticated/sessions/$sessionId/test-info': typeof AuthenticatedSessionsSessionIdTestInfoRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/callback'
    | '/donor/success'
    | '/sessions/$sessionId'
    | '/donor/signing/$transactionId'
    | '/sessions'
    | '/sessions/$sessionId/created'
    | '/sessions/$sessionId/donor-info'
    | '/sessions/$sessionId/sampling'
    | '/sessions/$sessionId/sign'
    | '/sessions/$sessionId/signed'
    | '/sessions/$sessionId/test-info'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/callback'
    | '/donor/success'
    | '/sessions/$sessionId'
    | '/donor/signing/$transactionId'
    | '/sessions'
    | '/sessions/$sessionId/created'
    | '/sessions/$sessionId/donor-info'
    | '/sessions/$sessionId/sampling'
    | '/sessions/$sessionId/sign'
    | '/sessions/$sessionId/signed'
    | '/sessions/$sessionId/test-info'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/callback'
    | '/donor/success'
    | '/_authenticated/sessions/$sessionId'
    | '/donor/signing/$transactionId'
    | '/_authenticated/sessions/'
    | '/_authenticated/sessions/$sessionId/created'
    | '/_authenticated/sessions/$sessionId/donor-info'
    | '/_authenticated/sessions/$sessionId/sampling'
    | '/_authenticated/sessions/$sessionId/sign'
    | '/_authenticated/sessions/$sessionId/signed'
    | '/_authenticated/sessions/$sessionId/test-info'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  CallbackRoute: typeof CallbackRoute
  DonorSuccessRoute: typeof DonorSuccessRoute
  DonorSigningTransactionIdRoute: typeof DonorSigningTransactionIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  CallbackRoute: CallbackRoute,
  DonorSuccessRoute: DonorSuccessRoute,
  DonorSigningTransactionIdRoute: DonorSigningTransactionIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/callback",
        "/donor/success",
        "/donor/signing/$transactionId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/sessions/$sessionId",
        "/_authenticated/sessions/"
      ]
    },
    "/callback": {
      "filePath": "callback.tsx"
    },
    "/donor/success": {
      "filePath": "donor/success.tsx"
    },
    "/_authenticated/sessions/$sessionId": {
      "filePath": "_authenticated/sessions/$sessionId/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/sessions/$sessionId/created",
        "/_authenticated/sessions/$sessionId/donor-info",
        "/_authenticated/sessions/$sessionId/sampling",
        "/_authenticated/sessions/$sessionId/sign",
        "/_authenticated/sessions/$sessionId/signed",
        "/_authenticated/sessions/$sessionId/test-info"
      ]
    },
    "/donor/signing/$transactionId": {
      "filePath": "donor/signing/$transactionId.tsx"
    },
    "/_authenticated/sessions/": {
      "filePath": "_authenticated/sessions/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/sessions/$sessionId/created": {
      "filePath": "_authenticated/sessions/$sessionId/created.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/donor-info": {
      "filePath": "_authenticated/sessions/$sessionId/donor-info.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/sampling": {
      "filePath": "_authenticated/sessions/$sessionId/sampling.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/sign": {
      "filePath": "_authenticated/sessions/$sessionId/sign.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/signed": {
      "filePath": "_authenticated/sessions/$sessionId/signed.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    },
    "/_authenticated/sessions/$sessionId/test-info": {
      "filePath": "_authenticated/sessions/$sessionId/test-info.tsx",
      "parent": "/_authenticated/sessions/$sessionId"
    }
  }
}
ROUTE_MANIFEST_END */
