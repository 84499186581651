import { z } from 'zod';
import { BankIdInternalCheckResponseDtoSchema } from './dto';

export const ActiveOrderSchema = z.object({
  orderRef: z.string(),
  qrStartToken: z.string(),
  qrStartSecret: z.string(),
  orderTimestamp: z.number(),
  lastPollTimestamp: z.number(),
  autoStartToken: z.string(),
  collectionResult: BankIdInternalCheckResponseDtoSchema.optional(),
});

export type ActiveOrder = z.infer<typeof ActiveOrderSchema>;
