import { Button } from '@careos/react-ui/Button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@careos/react-ui/InputOTP';
import { PdfRender } from '@careos/react-ui/PdfRender';
import { Spinner } from '@careos/react-ui/Spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { Signature } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ErrorAlert } from '@/components/error-alert';
import { useDonorGetRequisition } from '@/features/order/form/api/create-donor-get-requisition';
import { useDonorSignRequisition } from '@/features/order/form/api/create-donor-sign-requisition';
import { useDonorTransactionIsValid } from '@/features/order/form/api/get-donor-is-transaction-valid';
import { SignaturePad } from '@/features/order/form/components/signature-pad';
import { getSignatureDimensions } from '@/features/order/form/helpers/get-signature-dimensions';

export const Route = createFileRoute('/donor/signing/$transactionId')({
  component: DonorSigningComponent,
});

function DonorSigningComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });
  const { transactionId } = Route.useParams();
  const [isSigning, setIsSigning] = useState(false);
  const { navigate } = useRouter();

  const transactionQuery = useDonorTransactionIsValid({ transactionId });
  const getRequisitionMutation = useDonorGetRequisition({
    mutationConfig: {
      onError: (error) => {
        // TODO: make error type safe
        if (error.statusCode === 401) {
          form.reset();
          form.setError('pin', {
            message: t(
              `donor.error.${error.data.error as 'EXPIRED' | 'INVALID'}`, // TODO: replace this with when error object is typed
            ),
          });
        }
      },
    },
  });
  const donorSignMutation = useDonorSignRequisition({
    mutationConfig: {
      onSuccess: () => navigate({ to: '/donor/success' }),
    },
  });

  const FormSchema = z.object({
    pin: z.string().length(4),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: '',
    },
  });

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    getRequisitionMutation.mutate({ transactionId, pinCode: data.pin });
  };

  const handleSignatureSubmit = async (signature: string) => {
    donorSignMutation.mutate({
      pinCode: form.getValues().pin,
      signature: {
        signatureType: 'HANDWRITTEN',
        dimensions: await getSignatureDimensions(signature),
        signatureData: signature,
      },
      transactionId,
    });
  };

  if (getRequisitionMutation.isPending || donorSignMutation.isPending) {
    return (
      <div className="flex h-dvh items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (donorSignMutation.isError || transactionQuery.isError) {
    return (
      <div className="p-2">
        <ErrorAlert />
      </div>
    );
  }

  if (transactionQuery.data?.valid === false) {
    return (
      <div className="p-2">
        <ErrorAlert title={t('donor.session_expired')} />
      </div>
    );
  }

  if (getRequisitionMutation.isSuccess)
    return (
      <>
        {isSigning ? (
          <div className="flex h-dvh">
            <SignaturePad
              onSubmitSignature={handleSignatureSubmit}
              loading={donorSignMutation.isPending}
              className="my-auto"
            />
          </div>
        ) : (
          <div className="mx-auto flex max-w-[800px] flex-col">
            <PdfRender pdfSource={getRequisitionMutation.data.pdf} />
            <Button
              size="lg"
              className="my-2 rounded-sm"
              onClick={() => setIsSigning(true)}
            >
              <Signature /> {t('sign_by_hand')}
            </Button>
          </div>
        )}
      </>
    );

  if (transactionQuery.data?.valid) {
    return (
      <div className="flex h-dvh items-center justify-center px-2">
        <Form {...form}>
          <form className="space-y-6 rounded-xl bg-white p-4 ">
            <FormField
              control={form.control}
              name="pin"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('donor.otp_label')}</FormLabel>
                  <FormControl>
                    <InputOTP
                      autoComplete="off"
                      onComplete={form.handleSubmit(onSubmit)}
                      maxLength={4}
                      {...field}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormDescription>
                    {t('donor.otp_description')}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
    );
  }
}
