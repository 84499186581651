import { z } from 'zod';

export const CareosUserSchema = z.object({
  givenName: z.string(),
  familyName: z.string(),
  userId: z.string(),
  username: z.string(),
  permissions: z.array(z.string()),
  connections: z.array(z.string()).optional(),
  location: z.string().optional(),
  organization: z.string().optional(),
  personalId: z.string().optional(),
  // TODO: Migrate user locale to sv-SE, in the integration tests
  // we have just "sv" and that might still be the locale for users,
  // although, we don't actually use this locale.
  locale: z.string().optional(),
});

export type CareosUser = z.infer<typeof CareosUserSchema>;
