import type {
  InitiateRemoteSigningResponseDto,
  RemoteSignatureEventMessageDto,
} from '@careos/toxicology-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRemoteSignatureSse } from '../hooks/use-remote-signature-sse';

type RequisitionFormSignRemoteProps = InitiateRemoteSigningResponseDto & {
  onDonorSigned: (
    transactionId: RemoteSignatureEventMessageDto['transactionId'],
  ) => void;
};

export const RequisitionFormSignRemote = ({
  pinCode,
  transactionId,
  onDonorSigned,
}: RequisitionFormSignRemoteProps) => {
  const { t } = useTranslation();
  const { sseData } = useRemoteSignatureSse(transactionId);

  useEffect(() => {
    if (sseData?.status === 'DONOR_SIGNED') {
      onDonorSigned(sseData.transactionId);
    }
  }, [sseData?.status, onDonorSigned, sseData?.transactionId]);

  return (
    <div className="w-full rounded-md border border-green-500 p-4 text-center text-green-800">
      {`${t('requisition_form.signing.otp')}: ${pinCode}`}
    </div>
  );
};
