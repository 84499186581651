import type {
  BankIdCheckResponseDto,
  BankIdRequestErrorResponseDto,
} from '@careos/types';

import { QRScanPending } from './QRScanPending';
import { cn, enforceExhaustive } from '#utils';
import { useTranslation } from 'react-i18next';
import { QRScanFailed, type QRScanFailedActions } from './QRScanFailed';
import { QRScanError } from './QRScanError';
import { QRScanComplete } from './QRScanComplete';

export type BankIdError = BankIdRequestErrorResponseDto & { status: 'error' };
export type BankIdResponse = BankIdCheckResponseDto | BankIdError;
export type BankIdProps = BankIdResponse & QRScanFailedActions;

export const BankId = (props: BankIdProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });

  const { status } = props;

  const renderQRComponent = () => {
    switch (status) {
      case 'pending':
        return <QRScanPending {...props} />;
      case 'complete':
        return <QRScanComplete />;
      case 'failed':
        return <QRScanFailed {...props} />;
      case 'error':
        return <QRScanError errorCode={props.errorCode} />;
      default:
        enforceExhaustive(status);
    }
  };

  const errorClasses = 'bg-red-100 text-red-950';
  const statusColor = {
    pending: 'bg-white text-black',
    complete: 'bg-green-100 text-green-900',
    failed: errorClasses,
    error: errorClasses,
  }[status];

  const heading =
    status === 'complete' ? t('sign-success') : t('sign-with-bankid');

  return (
    <div
      className={cn(
        'text-center flex flex-col items-center p-8 md:p-16 min-h-[500px] relative w-full',
        statusColor,
      )}
    >
      {status !== 'error' && (
        <h2 className="text-3xl mb-8 font-extrabold">{heading}</h2>
      )}

      {renderQRComponent()}
    </div>
  );
};
