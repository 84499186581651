import { z } from 'zod';

import { KeyNameSchema, nonEmptyString } from '@careos/types';
import { OrganizationTypeSchema } from './organization-type';
export type KeyName = z.infer<typeof KeyNameSchema>;

export const OrganizationEntitySchema = KeyNameSchema.extend({
  createdAt: nonEmptyString,
});

// TS representations of orgs-api tables!
export const OrganizationSchema = OrganizationEntitySchema.extend({
  type: OrganizationTypeSchema,
  loginName: z.string(),
});

export const EmployerSchema = OrganizationEntitySchema;
export const SubdivisionSchema = OrganizationEntitySchema;
export const TreatmentCenterSchema = OrganizationEntitySchema;

export type Organization = z.infer<typeof OrganizationSchema>;
export type Employer = z.infer<typeof EmployerSchema>;
export type Subdivision = z.infer<typeof SubdivisionSchema>;
export type TreatmentCenter = z.infer<typeof TreatmentCenterSchema>;

// toxi DTOs only
export const BaseOrganizationSchema = z.object({
  display: nonEmptyString,
  organizationId: nonEmptyString,
});

export type OrganizationKeyType = {
  organization_key: string;
  organization_type: string;
};
