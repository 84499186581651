import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@careos/react-ui/Card';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { RequisitionCreated } from '@/features/order/form/components/requisition-created';

export const Route = createFileRoute(
  '/_authenticated/sessions/$sessionId/created',
)({
  beforeLoad: ({ context: { collectionStore } }) => {
    if (!collectionStore.created) {
      throw redirect({
        from: '/sessions/$sessionId/created',
        to: '../test-info',
      });
    }
    return collectionStore.created;
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.created',
  });
  const { donorPassword, pdf } = Route.useRouteContext();

  return (
    <Card className="mx-auto mt-8 w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t('title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex w-[800px] flex-col gap-y-4">
          <RequisitionCreated donorPassword={donorPassword} pdf={pdf} />
        </div>
      </CardContent>
    </Card>
  );
}
