import { Link } from '@tanstack/react-router';

export const AbcLogo = () => {
  return (
    <Link title="abc logo" to="/">
      <svg
        viewBox="0 0 170 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="size-[48px]"
        data-testid="abc-logo"
      >
        <path
          d="M25.42.007H11.67L.87 10.81v43.208h9.82v-19.64h19.64v19.64h9.82v-39.28c0-8.15-6.677-14.73-14.73-14.73zm-14.73 24.55v-9.82l4.91-4.91h9.82c2.65 0 4.91 2.16 4.91 4.91v9.82H10.69zM78.097 25.146c2.651-2.651 4.32-6.383 4.32-10.409V10.81L71.615.007H44.12v54.01h24.55c8.053 0 14.73-6.58 14.73-14.73V30.45l-5.302-5.303zM67.687 9.827l4.91 4.91c0 2.75-2.258 4.91-4.91 4.91H53.94v-9.82h13.748zm5.892 29.46c0 2.75-2.258 4.91-4.91 4.91H53.94v-14.73h14.73l4.91 4.91v4.91zM97.19 14.737c0-2.75 2.258-4.91 4.91-4.91h9.82l7.856 7.856 6.874-6.874L115.848.007H102.1c-8.053 0-14.73 6.58-14.73 14.73v28.478l10.802 10.802h28.478v-9.82H102.1l-4.91-4.91v-24.55zM10.69 56.99H.87V111h39.28v-9.82H10.69V56.99zM58.85 56.99h13.747L83.4 67.792V111h-9.82V91.36H53.94V111h-9.82V71.72c0-8.15 6.677-14.73 14.73-14.73zm14.73 24.55v-9.82l-4.91-4.91h-9.82c-2.652 0-4.91 2.16-4.91 4.91v9.82h19.64zM121.347 82.13c2.651-2.652 4.321-6.384 4.321-10.41v-3.928L114.866 56.99H87.37V111h24.55c8.052 0 14.73-6.579 14.73-14.73v-8.838l-5.303-5.303zm-10.409-15.32l4.91 4.91c0 2.75-2.259 4.91-4.91 4.91H97.19v-9.82h13.748zm5.892 29.46c0 2.75-2.259 4.91-4.91 4.91H97.19V86.45h14.73l4.91 4.91v4.91zM168.9 56.99h-28.478L129.62 67.792v3.928c0 8.15 6.678 14.73 14.73 14.73h7.365c3.928-.098 7.267 3.24 7.365 7.365v2.455l-4.91 4.91h-24.55V111h28.478l10.802-10.802v-6.383c-.098-9.624-7.758-17.283-17.185-17.185h-7.365c-2.651 0-4.91-2.16-4.91-4.91l4.91-4.91h24.55v-9.82z"
          fill="#4031c6"
        ></path>
      </svg>
    </Link>
  );
};
