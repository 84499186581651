import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@careos/react-ui/AlertDialog';
import { useToast } from '@careos/react-ui/Toast';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateFinishSession } from '@/features/order/table/api/create-finish-sampling-session';
import { useCreateStartSamplingSession } from '@/features/order/table/api/create-start-sampling-session';
import { getSessionsQueryOptions } from '@/features/order/table/api/get-sampling-sessions';
import { getSessionColumns } from '@/features/order/table/components/columns';
import { DataTable } from '@/features/order/table/components/data-table';
import { NumberGenerator } from '@/features/order/table/components/number-generator';

export const Route = createFileRoute('/_authenticated/sessions/')({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getSessionsQueryOptions());
  },
  component: SamplingSessionsComponent,
});

function SamplingSessionsComponent() {
  const navigate = Route.useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation('translation', { keyPrefix: 'table' });
  const [finishSessionModalOpen, setFinishSessionModalOpen] = useState(false);
  const [isNumberGeneratorModalOpen, setIsNumberGeneratorModalOpen] =
    useState(false);
  const [sessionId, setSessionId] = useState('');

  const { data } = useSuspenseQuery(getSessionsQueryOptions());

  const createStartSamplingSessionMutation = useCreateStartSamplingSession({
    mutationConfig: {
      onSuccess: ({ sessionId }) =>
        navigate({
          to: '/sessions/$sessionId/test-info',
          params: { sessionId },
        }),
    },
  });
  const createFinishSamplingSessionMutation = useCreateFinishSession({
    mutationConfig: {
      onSuccess: () => {
        toast({
          description: t('finish_session_dialog.toast_message.success'),
        });
      },
      onError: () => {
        toast({
          variant: 'destructive',
          description: t('finish_session_dialog.toast_message.error'),
        });
      },
    },
  });

  const handleStartSession = useCallback(
    (sessionId: string) =>
      createStartSamplingSessionMutation.mutate({ sessionId }),
    [createStartSamplingSessionMutation],
  );

  const handleFinishSession = useCallback((sessionId: string) => {
    setFinishSessionModalOpen(true);
    setSessionId(sessionId);
  }, []);

  const handleGenerateNumbers = useCallback(() => {
    setIsNumberGeneratorModalOpen(true);
  }, []);

  const sessionColumns = useMemo(
    () =>
      getSessionColumns({
        onStartSession: handleStartSession,
        onFinishSession: handleFinishSession,
        onGenerateNumbers: handleGenerateNumbers,
      }),
    [handleStartSession, handleFinishSession, handleGenerateNumbers],
  );

  return (
    <div className="container mx-auto py-10">
      <NumberGenerator
        isGeneratorDialogOpen={isNumberGeneratorModalOpen}
        onOpenChange={setIsNumberGeneratorModalOpen}
      />
      <AlertDialog
        open={finishSessionModalOpen}
        onOpenChange={setFinishSessionModalOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('finish_session_dialog.title')}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t('finish_session_dialog.description')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t('finish_session_dialog.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                createFinishSamplingSessionMutation.mutate({ sessionId });
              }}
            >
              {t('finish_session_dialog.confirm')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <DataTable columns={sessionColumns} data={data?.samplingSessions ?? []} />
    </div>
  );
}
