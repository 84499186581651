import { useMutation } from '@tanstack/react-query';

import { api } from '@/lib/api';
import { type MutationConfig } from '@/lib/react-query';

export const useCreateFinishSession = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof api.samplingSession.finish>;
} = {}) => {
  const { ...restConfig } = mutationConfig || {};

  return useMutation({
    ...restConfig,
    mutationFn: api.samplingSession.finish,
  });
};
