import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { HandwrittenSignRequestSignatureSchema } from '../../../../common';
import { DonorGetRequisitionByTransactionIdRequestDtoSchema } from './donor-get-requisition-by-transaction-id.request.dto';

export const HandwrittenSignRequestSchema = z.object({
  transactionId: nonEmptyString,
  signature: HandwrittenSignRequestSignatureSchema,
});

export const DonorHandwrittenSignRequestDtoSchema =
  HandwrittenSignRequestSchema.merge(
    DonorGetRequisitionByTransactionIdRequestDtoSchema,
  );

export type HandwrittenSignRequestDto = z.infer<
  typeof HandwrittenSignRequestSchema
>;

export type DonorHandwrittenSignatureRequestDto = z.infer<
  typeof DonorHandwrittenSignRequestDtoSchema
>;

export const isDonorHandwrittenSignRequest = (
  result: unknown,
): result is DonorHandwrittenSignatureRequestDto =>
  DonorHandwrittenSignRequestDtoSchema.strict().safeParse(result).success;
