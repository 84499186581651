import { Checkbox } from '@careos/react-ui/Checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from '@careos/react-ui/Form';
import { Input } from '@careos/react-ui/Input';
import { RadioGroup, RadioGroupItem } from '@careos/react-ui/RadioGroup';
import { Textarea } from '@careos/react-ui/TextArea';
import { type BarcodeResponseBody } from '@careos/toxicology-types';
import { nonEmptyString } from '@careos/types';
import { zodResolver } from '@hookform/resolvers/zod';
import i18next from 'i18next';
import { ListCheck } from 'lucide-react';
import { FetchError } from 'ofetch';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useCheckBarcode } from '../api/create-check-barcode';

import { BackButton } from './back-button';
import { NextButton } from './next-button';

const SamplingFormSchema = z.object({
  comment: z.object({
    value: z.string().trim().min(1, {
      message:
        'If the person has no prescriptions/comment, tick the box. Otherwise list the prescriptions/enter any comments.',
    }),
    hasNoPrescription: z.boolean().optional().default(false),
  }),
  barcode: z
    .object({
      value: nonEmptyString,
      confirmation: nonEmptyString,
    })
    .refine((val) => val.value === val.confirmation, {
      message: i18next.t(
        'requisition_form.sampling.barcode.validation.mismatch',
      ),
      path: ['confirmation'],
    }),
});

const UrineTemperatureSchema = z.object({
  urineTemperature: z
    .enum(['valid', 'not_normal'])
    .refine((val) => val !== 'not_normal', {
      message: i18next.t(
        'requisition_form.sampling.urine_temperature.validation.not_normal',
      ),
    }),
});

const SamplingFormSchemaWithUrine = SamplingFormSchema.merge(
  UrineTemperatureSchema,
);

export type SamplingForm = z.infer<
  typeof SamplingFormSchema | typeof SamplingFormSchemaWithUrine
>;

type Props = {
  onUpdate: (
    formVals: SamplingForm,
    stepDirection: 'previous' | 'next',
  ) => void;
  isSampleTypeUrine: boolean;
  isDoASampling: boolean;
  formVals: Partial<SamplingForm>;
};

export const RequisitionFormSampling = ({
  onUpdate,
  formVals,
  isDoASampling,
  isSampleTypeUrine = false,
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.sampling',
  });

  const FormSchema = isSampleTypeUrine
    ? SamplingFormSchemaWithUrine
    : SamplingFormSchema;

  const checkBarcodeMutation = useCheckBarcode({
    mutationConfig: {
      onError: (error: FetchError<BarcodeResponseBody>) => {
        form.setError('barcode.confirmation', {
          message: t(`barcode.error.${error.data!.reason}`),
        });
      },
      onSuccess: () => {
        onUpdate(form.getValues(), 'next');
      },
    },
  });

  const form = useForm<SamplingForm>({
    resolver: zodResolver(FormSchema),
    defaultValues: formVals,
  });

  const onSubmit = async (data: SamplingForm) => {
    checkBarcodeMutation.mutate(data.barcode.value);
  };

  function handleGoBack() {
    onUpdate(form.getValues(), 'previous');
  }

  const getSamplingComment = useCallback(() => {
    return isDoASampling ? t('no_prescription.label') : t('no_comment.label');
  }, [isDoASampling, t]);

  useEffect(() => {
    if (form.watch('comment.hasNoPrescription')) {
      form.setValue('comment.value', getSamplingComment());
    }
  }, [isDoASampling, form, getSamplingComment]);

  return (
    <div className="w-[400px]">
      <div className="flex items-center gap-x-4 py-4 text-indigo-700">
        <ListCheck />
        <span className="text-xs underline">{t('checklist')}</span>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          {isSampleTypeUrine && (
            <FormField
              control={form.control}
              name="urineTemperature"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel className="text-base">
                    {t('urine_temperature.label')}
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(val) => {
                        field.onChange(val);
                        form.trigger('urineTemperature');
                      }}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="valid" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {t('urine_temperature.range')}
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="not_normal" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {t('urine_temperature.not_normal')}
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <div className="space-y-1">
            <h3>{t('comments.label')}</h3>
            <FormField
              control={form.control}
              name="comment.hasNoPrescription"
              render={({ field }) => (
                <FormItem className="my-3 flex items-center gap-x-2 rounded-md p-1">
                  <FormControl>
                    <Checkbox
                      className="size-4 leading-none"
                      checked={field.value}
                      onCheckedChange={(val) => {
                        field.onChange(val);
                        form.setValue(
                          'comment.value',
                          val ? getSamplingComment() : '',
                        );
                      }}
                    />
                  </FormControl>
                  <FormLabel className="leading-1 text-gray-500">
                    {getSamplingComment()}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="comment.value"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder={
                        isDoASampling
                          ? t('comments.placeholder.drug')
                          : t('comments.placeholder.peth')
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="space-y-3">
            <h3>{t('barcode.label')}</h3>
            <FormField
              control={form.control}
              name="barcode.value"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={t('barcode.value.placeholder')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="barcode.confirmation"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={t('barcode.confirmation.placeholder')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-between">
            <BackButton onClick={handleGoBack} />
            <NextButton className="w-1/2" />
          </div>
        </form>
      </Form>
    </div>
  );
};
