import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { QueryConfig } from '@/lib/react-query';

export const getSessionQueryOptions = (sessionId: string) => {
  return queryOptions({
    queryKey: ['sampling-session', sessionId],
    queryFn: () => api.samplingSessions.getById(sessionId),
  });
};

export const useSamplingSession = ({
  sessionId,
  queryConfig,
}: {
  sessionId: string;
  queryConfig?: QueryConfig<typeof getSessionQueryOptions>;
}) => {
  return useQuery({
    ...getSessionQueryOptions(sessionId),
    ...queryConfig,
  });
};
