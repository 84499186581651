import { AbcLogo } from './AbcLogo';
import { UserMenu, type UserMenuProps } from './UserMenu';
import { NavigationItems, type NavigationItemsProps } from './NavigationItems';

export type HeaderProps = UserMenuProps &
  Partial<Omit<NavigationItemsProps, 'className'>>;

export const Header = ({ user, linkOptions, ...rest }: HeaderProps) => {
  return (
    <header className="sticky top-0 z-50 w-full border-b-2 border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 max-w-screen-xl items-center">
        <AbcLogo />
        {linkOptions && (
          <NavigationItems className="ml-24" linkOptions={linkOptions} />
        )}
        <div className="ml-auto flex items-center gap-x-2">
          <span className=" text-slate-500">
            {user.organization.display_name}
          </span>
          <UserMenu user={user} {...rest} />
        </div>
      </div>
    </header>
  );
};
