import { Button } from '@careos/react-ui/Button';
import type { Donor } from '@careos/toxicology-types';
import { Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';

const SHOW_NAME_DURATION = 5000;

export const DonorName = ({ donor }: { donor: Donor }) => {
  const [hidden, setHidden] = useState(true);

  const toggleHidden = () => {
    setHidden((prevHidden) => {
      if (prevHidden) {
        setTimeout(() => setHidden(true), SHOW_NAME_DURATION);
      }
      return !prevHidden;
    });
  };

  const name = `${donor.firstName} ${donor.lastName}`.trim();

  return (
    <>
      <Button onClick={() => toggleHidden()} variant={'ghost'} size={'sm'}>
        {hidden ? (
          <>
            <span>************</span>
            <EyeOff className="ml-2 size-4" />
          </>
        ) : (
          <>
            <span>{name}</span>
            <Eye className="ml-2 size-4" />
          </>
        )}
      </Button>
    </>
  );
};
