import { z } from 'zod';
import { BankIdNativeCompletionDataSchema } from '../native-types/bankid-native-completion-data';
import { KnownHintCodeSchema } from '../native-types/const/hint-code';
import { BankIdStatus } from '../native-types/const/status';
import { UnknownCode } from '../const/unknown';

export const SigningCompletionDataSchema =
  BankIdNativeCompletionDataSchema.pick({
    signature: true,
    user: true,
  }).extend({ completionDate: z.string().datetime() });
export type SigningCompletionData = z.infer<typeof SigningCompletionDataSchema>;

export const HintCodeSchema = z.enum([
  ...KnownHintCodeSchema.options,
  UnknownCode,
]);

export const HintCode = HintCodeSchema.Values;
export type HintCode = z.infer<typeof HintCodeSchema>;

export const PendingResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: z.literal(BankIdStatus.pending),
  qrText: z.string(),
  hintCode: HintCodeSchema,
});
export type PendingResponseDto = z.infer<typeof PendingResponseDtoSchema>;

export const FailedResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: z.literal(BankIdStatus.failed),
  hintCode: HintCodeSchema,
});

export type FailedResponseDto = z.infer<typeof FailedResponseDtoSchema>;

const BankIdCompletionResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: z.literal(BankIdStatus.complete),
  completionData: SigningCompletionDataSchema,
});

export type BankIdCompletionResponseDto = z.infer<
  typeof BankIdCompletionResponseDtoSchema
>;

export const BankIdInternalCheckResponseDtoSchema = z.discriminatedUnion(
  'status',
  [
    PendingResponseDtoSchema,
    FailedResponseDtoSchema,
    BankIdCompletionResponseDtoSchema,
  ],
);

export type BankIdInternalCheckResponseDto = z.infer<
  typeof BankIdInternalCheckResponseDtoSchema
>;
