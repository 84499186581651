import { DropdownMenuItem } from '#components/DropdownMenu/DropdownMenu';
import { LogOut as LogOutIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type Props = {
  onLogout: () => void;
};

export const LogOut = ({ onLogout }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'generic.user_menu',
  });
  return (
    <DropdownMenuItem onClick={onLogout}>
      <LogOutIcon className="mr-2 size-4" />
      {t('logout')}
    </DropdownMenuItem>
  );
};
