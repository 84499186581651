import { DropdownMenuItem } from '#components/DropdownMenu/DropdownMenu';
import { Mail } from 'lucide-react';
import type { Auth0CareosUser } from '../AuthenticatedComponent';

type Props = {
  email: Auth0CareosUser['email'];
};

export const UserEmail = ({ email }: Props) => {
  return (
    <DropdownMenuItem disabled>
      <Mail className="mr-2 size-4" />
      {email}
    </DropdownMenuItem>
  );
};
