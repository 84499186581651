import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { QueryConfig } from '@/lib/react-query';

export const getSignedRequisitionByTransactionIdOptions = (
  transactionId: string,
) => {
  return queryOptions({
    queryKey: ['signed-pdf', transactionId],
    queryFn: () =>
      api.requisition.getSignedRequisitionByTransactionId(transactionId),
  });
};

export const useSignedRequisition = ({
  transactionId,
  queryConfig,
}: {
  transactionId: string;
  queryConfig?: QueryConfig<typeof getSignedRequisitionByTransactionIdOptions>;
}) => {
  return useQuery({
    ...getSignedRequisitionByTransactionIdOptions(transactionId),
    ...queryConfig,
  });
};
