import { z } from 'zod';

export const BankIdNativeSignResponseDtoSchema = z.object({
  orderRef: z.string(),
  autoStartToken: z.string(),
  qrStartToken: z.string(),
  qrStartSecret: z.string(),
});

export type BankIdNativeSignResponseDto = z.infer<
  typeof BankIdNativeSignResponseDtoSchema
>;
