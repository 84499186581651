import { z } from 'zod';

export const SignatureTypeSchema = z.enum(['HANDWRITTEN', 'BANKID']);

export const SignatureType = SignatureTypeSchema.enum;
export type SignatureType = z.infer<typeof SignatureTypeSchema>;

const BaseSignatureDataSchema = z.object({
  signatureData: z.string(),
  signingDate: z.string().datetime(),
});

export const HandwrittenSignatureDataSchema = BaseSignatureDataSchema.merge(
  z.object({
    signatureType: z.literal(SignatureType.HANDWRITTEN),
  }),
);

export const SignatureDimensionsSchema = z.object({
  width: z.number(),
  height: z.number(),
});

export const BankIdSignatureDataSchema = BaseSignatureDataSchema.merge(
  z.object({
    signatureType: z.literal(SignatureType.BANKID),
  }),
);

export const SignatureDataSchema = z.union([
  HandwrittenSignatureDataSchema,
  BankIdSignatureDataSchema,
]);

export const BankIdSignatureWithoutSignatureDataSchema =
  BankIdSignatureDataSchema.omit({
    signatureData: true,
  });

export const HandwrittenSignatureWithDimensionsDataSchema =
  HandwrittenSignatureDataSchema.extend({
    dimensions: SignatureDimensionsSchema,
  });

export const HandwrittenSignRequestSignatureSchema =
  HandwrittenSignatureWithDimensionsDataSchema.omit({ signingDate: true });

export type HandwrittenSignRequestSignatureDto = z.infer<
  typeof HandwrittenSignRequestSignatureSchema
>;

export const SignatureDataWithDimensionsSchema = z.discriminatedUnion(
  'signatureType',
  [HandwrittenSignatureWithDimensionsDataSchema, BankIdSignatureDataSchema],
);

export type SignatureDataWithDimensions = z.infer<
  typeof SignatureDataWithDimensionsSchema
>;

export const SigningDisplaySchema = z.discriminatedUnion('signatureType', [
  HandwrittenSignatureDataSchema,
  BankIdSignatureWithoutSignatureDataSchema,
]);

export type SignatureData = z.infer<typeof SignatureDataSchema>;

export type SignatureDimensions = z.infer<typeof SignatureDimensionsSchema>;

export type SigningDisplay = z.infer<typeof SigningDisplaySchema>;

export const mapSignatureData = (signature: SignatureData): SigningDisplay => {
  if (signature.signatureType === SignatureType.HANDWRITTEN) {
    return {
      signatureType: SignatureType.HANDWRITTEN,
      signatureData: `data:image/png;base64,${signature.signatureData}`,
      signingDate: signature.signingDate,
    };
  }
  return {
    signatureType: SignatureType.BANKID,
    signingDate: signature.signingDate,
  };
};
