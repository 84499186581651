// TODO: Translations

import { AlertTriangle } from 'lucide-react';
import { type FallbackRender } from '@sentry/react';
import { Alert, AlertDescription, AlertTitle } from '#components/Alert/Alert';
import { Button } from '#components/Button/Button';

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '#components/Card/Card';
import { useTranslation } from 'react-i18next';

type ErrorFallbackProps = Partial<Parameters<FallbackRender>[0]>;

/**
 * Fallback component to display when an error occurs in an ErrorBoundary.
 * Displays a generic error message and provides a button to reset the error.
 */
export const ErrorFallback = ({ error, resetError }: ErrorFallbackProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'generic' });

  const errorMessage = (error as Error)?.message || t('error.message');

  const handleReset = () => {
    resetError?.();
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      data-testid="error-fallback"
    >
      <Card className="w-full max-w-xl">
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-destructive">
            <AlertTriangle className="h-6 w-6" />
            {t('error.message')}
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert variant="destructive">
            <AlertTitle>{t('error.title')}</AlertTitle>
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
          <p className="text-sm text-muted-foreground">{t('error.action')}</p>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button
            variant="outline"
            onClick={() => (window.location.href = '/')}
          >
            {t('navigation.goToStartPage')}
          </Button>
          {resetError && <Button onClick={handleReset}>{t('retry')}</Button>}
        </CardFooter>
      </Card>
    </div>
  );
};
