import { z } from 'zod';
import { BankIdStatus } from '../native-types/const/status';
import {
  FailedResponseDtoSchema,
  PendingResponseDtoSchema,
} from './bankid-internal-check-response.dto';

const CleanCompletionResponseDtoSchema = z.object({
  orderRef: z.string(),
  transactionId: z.string(),
  status: z.literal(BankIdStatus.complete),
});

export const CheckResponseDtoSchema = z.discriminatedUnion('status', [
  PendingResponseDtoSchema,
  FailedResponseDtoSchema,
  CleanCompletionResponseDtoSchema,
]);

export type BankIdCheckResponseDto = z.infer<typeof CheckResponseDtoSchema>;
