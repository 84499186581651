import { z } from 'zod';

export const GenerateRequisitionPdfResponseSchema = z.object({
  transactionId: z.string().uuid(),
  pdf: z.string().min(1),
});

export type GenerateRequisitionPdfResponseDto = z.infer<
  typeof GenerateRequisitionPdfResponseSchema
>;

export function isDoaRequisitionResponse(
  response: unknown,
): response is GenerateRequisitionPdfResponseDto {
  return GenerateRequisitionPdfResponseSchema.safeParse(response).success;
}
