import { genericEn } from './en-US';

export const genericSv: typeof genericEn = {
  retry: 'Försök igen',

  language: {
    language: 'Språk',
    english: 'Engelska',
    swedish: 'Svenska',
  },

  navigation: {
    goToStartPage: 'Gå till startsidan',
  },

  user_menu: {
    logout: 'Logga ut',
  },

  error: {
    title: 'Felmeddelande:',
    message: 'Ett fel inträffade',
    action:
      'Vänligen försök igen eller kontakta support om problemet kvarstår.',
  },

  form: {
    firstName: {
      label: 'Förnamn',
    },
    lastName: {
      label: 'Efternamn',
    },
    email: {
      label: 'E-postadress',
    },
    swedishPersonalNumber: {
      label: 'Svenskt personnummer',
      placeholder: 'ÅÅÅÅMMDD-XXXX',
    },
    employer: {
      label: 'Arbetsgivare',
      placeholder: 'Välj arbetsgivare',
    },

    optional: 'valfritt',
    back: 'Tillbaka',
    next: 'Nästa',
  },
};
