import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@careos/react-ui/Card';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { getSessionQueryOptions } from '@/features/order/form/api/get-sampling-session';
import { RequisitionFormDonorInfo } from '@/features/order/form/components/requisition-form-donor-info';
import { useCollectionStore } from '@/features/order/form/stores/collection-store';

export const Route = createFileRoute(
  '/_authenticated/sessions/$sessionId/donor-info',
)({
  loader: async ({ context, params }) =>
    await context.queryClient.ensureQueryData(
      getSessionQueryOptions(params.sessionId),
    ),
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.donor_info',
  });
  const donorInfoForm = useCollectionStore((state) => state.donorInfoForm);
  const updateDonorInfoForm = useCollectionStore(
    (state) => state.updateDonorInfoForm,
  );
  const params = Route.useParams();
  const navigate = Route.useNavigate();

  const { data } = useSuspenseQuery(getSessionQueryOptions(params.sessionId));
  const donor = data.samplingSession.donor;

  const handleDonorInfoUpdate = (
    donorInfo: RequisitionFormDonorInfo,
    stepDirection: 'previous' | 'next',
  ) => {
    updateDonorInfoForm(donorInfo);

    if (stepDirection === 'next') {
      navigate({ to: '../sampling' });
    } else {
      navigate({ to: '../test-info' });
    }
  };
  const defaultValues = donorInfoForm ?? {
    personalIdentifier: {
      system:
        donor?.personalIdentifier.system ??
        IdentifierSystem.PersonalIdentityNumber,
      value: donor?.personalIdentifier.value ?? '',
    },
    firstName: donor?.firstName ?? '',
    lastName: donor?.lastName ?? '',
    phoneNumber: donor?.phoneNumber ?? '',
    attester: {
      needsAttester: false,
    },
    collectorConfirm: false,
  };

  return (
    <Card className="mx-auto mt-8 w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t('title')}</CardTitle>
      </CardHeader>
      <CardContent>
        <RequisitionFormDonorInfo
          onUpdate={handleDonorInfoUpdate}
          defaultValues={defaultValues}
        />
      </CardContent>
    </Card>
  );
}
