import { Button, type ButtonProps } from '@careos/react-ui/Button';
import { cn } from '@careos/react-ui/utils';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const BackButton = ({ className, ...rest }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="ghost"
      type="button"
      {...rest}
      className={cn('gap-x-4 w-fit', className)}
    >
      <ArrowLeft className="aspect-square" />
      <span>{t('requisition_form.back')}</span>
    </Button>
  );
};
