/**
 * Asserts a variable as defined, if the variable is undefined throws an error
 */
export const assertDefined = <T>(
  value: T | null | undefined,
  message = 'Undefined value',
) => {
  if (value === null || value === undefined) throw new TypeError(message);
  return value;
};
