import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import { Button } from '@careos/react-ui/Button';
import { PdfRender } from '@careos/react-ui/PdfRender';
import type { GenerateRequisitionPdfResponseDto } from '@careos/toxicology-types';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BankIdQR } from '@/features/bank-id/components/bank-id-qr';

import { useInitiateRemoteSigning } from '../api/create-initiate-remote-signing';

import { BackButton } from './back-button';
import type { RequisitionFormDonorInfo } from './requisition-form-donor-info';
import { RequisitionFormSignRemote } from './requisition-form-sign-remote';
import { SignaturePadModal } from './signature-pad-modal';

type RequisitionFormSignProps = GenerateRequisitionPdfResponseDto & {
  onSuccessfulSigning: (transactionId: string) => void;
  onSubmitSignature: (signature: string) => Promise<void>;
  onSubmitBankIdSignature: (transactionId: string) => void;
  isSubmittingSignature: boolean;
  donorInfoForm: RequisitionFormDonorInfo;
};

export const RequisitionFormSign = ({
  onSuccessfulSigning,
  onSubmitSignature,
  onSubmitBankIdSignature,
  isSubmittingSignature,
  donorInfoForm,
  pdf,
  transactionId,
}: RequisitionFormSignProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });
  const navigate = useNavigate({ from: '/sessions/$sessionId/sign' });
  const initiateRemoteSigningMutation = useInitiateRemoteSigning();

  return (
    <div className="flex w-[800px] flex-col gap-y-4">
      <PdfRender pdfSource={pdf} />
      <div className="flex gap-x-4">
        {initiateRemoteSigningMutation.isSuccess ? (
          <RequisitionFormSignRemote
            onDonorSigned={(transactionId) =>
              onSuccessfulSigning(transactionId)
            }
            transactionId={initiateRemoteSigningMutation.data.transactionId}
            pinCode={initiateRemoteSigningMutation.data.pinCode}
          />
        ) : (
          <>
            <Button
              className="w-full py-8"
              onClick={() =>
                initiateRemoteSigningMutation.mutate({
                  transactionId: transactionId,
                })
              }
            >
              {t('start_remote_signing')}
            </Button>
            {donorInfoForm.personalIdentifier.system ===
              IdentifierSystem.PersonalIdentityNumber && (
              <BankIdQR
                onCompletion={onSubmitBankIdSignature}
                transactionId={transactionId}
              />
            )}
            <SignaturePadModal
              onSubmitSignature={onSubmitSignature}
              loading={isSubmittingSignature}
            />
          </>
        )}
      </div>
      <BackButton onClick={() => navigate({ to: '../sampling' })} />
    </div>
  );
};
