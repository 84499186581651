import { Auth0CareosUserSchema } from '@careos/react-ui/AuthenticatedComponent';
import {
  createFileRoute,
  Outlet,
  redirect,
  useBlocker,
} from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Route as createdRoute } from './created';
import { Route as testInfoRoute } from './test-info';

export const Route = createFileRoute('/_authenticated/sessions/$sessionId')({
  beforeLoad: ({ location, context, params }) => {
    const isFirstStep = location.pathname.includes(testInfoRoute.path);
    const isLastStep = location.pathname.includes(createdRoute.path);
    const isFirstOrLastStep = isFirstStep || isLastStep;

    if (!isFirstOrLastStep && !context.collectionStore.isDirtyForm()) {
      throw redirect({ to: testInfoRoute.fullPath, params });
    }

    const userResult = Auth0CareosUserSchema.safeParse(context.auth.user);
    if (!userResult.success) {
      throw new Error(userResult.error.message);
    }
    return { user: userResult.data };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form',
  });
  const { collectionStore } = Route.useRouteContext();

  useBlocker({
    shouldBlockFn: ({ next, current }) => {
      const isNextChild = next.fullPath.startsWith(Route.fullPath);

      const isCurrentCreatedRoute = current.fullPath === createdRoute.fullPath;
      if (isNextChild || isCurrentCreatedRoute) return false;

      const shouldLeave = confirm(t('leave_warning'));
      if (!shouldLeave) return true;

      collectionStore.resetFormState();
      return false;
    },
    enableBeforeUnload: true,
  });

  return <Outlet />;
}
