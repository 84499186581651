import { Button } from '@careos/react-ui/Button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@careos/react-ui/Collapsible';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@careos/react-ui/Table';
import type { SamplingSession } from '@careos/toxicology-types';
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ChevronRight } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import RequisitionDetailsTable from './row-expansion-table/requisition-data-table';

interface DataTableProps<TData> {
  columns: ColumnDef<TData, any>[];
  data: TData[];
}

export function DataTable<TData extends SamplingSession>({
  columns,
  data,
}: DataTableProps<TData>) {
  const [openCollapsibles, setOpenCollapsibles] = useState<Set<string>>(
    new Set(),
  );
  const { t } = useTranslation('translation');

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleCollapsibleChange = (
    isOpen: boolean,
    rowId: string,
    setOpenCollapsibles: React.Dispatch<React.SetStateAction<Set<string>>>,
  ) => {
    setOpenCollapsibles((prev) => {
      const newSet = new Set(prev);
      if (isOpen) {
        newSet.add(rowId);
      } else {
        newSet.delete(rowId);
      }
      return newSet;
    });
  };

  const renderEmptyState = () => {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {t('table.no_session')}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableHead></TableHead>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length
              ? table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <TableRow className="font-medium">
                      <TableCell>
                        {row.original.requisitions?.length ? (
                          <Collapsible
                            open={openCollapsibles.has(row.id)}
                            onOpenChange={(isOpen) => {
                              handleCollapsibleChange(
                                isOpen,
                                row.id,
                                setOpenCollapsibles,
                              );
                            }}
                          >
                            <CollapsibleTrigger asChild>
                              <Button variant="ghost" size="icon">
                                <ChevronRight
                                  className={`size-4 ${
                                    openCollapsibles.has(row.id)
                                      ? 'rotate-90'
                                      : ''
                                  }`}
                                />
                              </Button>
                            </CollapsibleTrigger>
                          </Collapsible>
                        ) : null}
                      </TableCell>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.original.requisitions?.length ? (
                      <TableRow>
                        <TableCell colSpan={columns.length + 1} className="p-0">
                          <Collapsible
                            open={openCollapsibles.has(row.id)}
                            onOpenChange={(isOpen) => {
                              handleCollapsibleChange(
                                isOpen,
                                row.id,
                                setOpenCollapsibles,
                              );
                            }}
                          >
                            <CollapsibleContent className="bg-slate-50 p-4 hover:bg-slate-50">
                              <RequisitionDetailsTable
                                requisitions={row.original.requisitions.map(
                                  (requisition) => ({
                                    id: requisition.id,
                                    barcode: requisition.barcode,
                                    receivedAt: requisition.receivedAt,
                                    testType: requisition.testType,
                                    sampleType: requisition.sampleType,
                                    panelSize: requisition.panelSize,
                                    samplingKit: requisition.samplingKit,
                                    additionalSubstances:
                                      requisition.additionalSubstances,
                                  }),
                                )}
                              />
                            </CollapsibleContent>
                          </Collapsible>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                ))
              : renderEmptyState()}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
