import { z } from 'zod';

/**
 * @deprecated LanguageSchema is deprecated. Use LocaleSchema instead.
 * Updated with the types from LocaleSchema to be able to retire this
 * schema when all events using the old (en/sv) structure have been removed.
 */
export const LanguageSchema = z.enum(['en', 'en-GB', 'sv', 'sv-SE', 'en-US']);

/**
 * Languages supported in Care OS
 */
export type Language = z.infer<typeof LanguageSchema>;
