import type { Auth0ContextInterface } from '@auth0/auth0-react';
import {
  isInvalidGrantError,
  isLoginRequiredError,
} from '@careos/react-ui/utils';
import { ofetch, type $Fetch } from 'ofetch';

let httpClient: $Fetch;

type CreateHttpClientParams = Pick<
  Auth0ContextInterface,
  'loginWithRedirect' | 'getAccessTokenSilently'
>;

const baseOptions = {
  baseURL: import.meta.env.VITE_COLLECTION_API_URL,
  retry: 0,
};

const tokenlessHttpClient = ofetch.create({
  ...baseOptions,
});

const createHttpClient = ({
  loginWithRedirect,
  getAccessTokenSilently,
}: CreateHttpClientParams) => {
  httpClient = ofetch.create({
    ...baseOptions,
    async onRequest({ options }) {
      try {
        const accessToken = await getAccessTokenSilently();
        options.headers.append('Authorization', `Bearer ${accessToken}`);
      } catch (error) {
        if (isInvalidGrantError(error) || isLoginRequiredError(error)) {
          loginWithRedirect({
            appState: { returnTo: window.location.origin },
          });
          return;
        }
        throw error;
      }
    },
  });
};

export { httpClient, createHttpClient, tokenlessHttpClient };
