import { Button } from '#components/Button/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '#components/DropdownMenu/DropdownMenu';

import {
  LanguageSelector,
  type LanguageSelectorProps,
} from './LanguageSelector';
import { LogOut } from './Logout';
import { UserEmail } from './UserEmail';
import type { Auth0CareosUser } from '../AuthenticatedComponent';

export type UserMenuProps = {
  user: Auth0CareosUser;
  onLogout: () => void;
  menuItems?: () => React.ReactNode;
} & LanguageSelectorProps;

export const UserMenu = ({
  onLogout,
  user,
  onLanguageChange,
  menuItems,
}: UserMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="rounded-full">
          {user.name}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <LanguageSelector onLanguageChange={onLanguageChange} />
        {menuItems && menuItems()}
        <LogOut onLogout={onLogout} />
        <DropdownMenuSeparator />
        <UserEmail email={user.email} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
