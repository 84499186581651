import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { QueryConfig } from '@/lib/react-query';

export const getDonorIsTransactionValidOptions = (transactionId: string) => {
  return queryOptions({
    queryKey: ['transaction-valid', transactionId],
    queryFn: () => api.requisition.donorIsTransactionValid(transactionId),
  });
};

export const useDonorTransactionIsValid = ({
  transactionId,
  queryConfig,
}: {
  transactionId: string;
  queryConfig?: QueryConfig<typeof getDonorIsTransactionValidOptions>;
}) => {
  return useQuery({
    ...getDonorIsTransactionValidOptions(transactionId),
    ...queryConfig,
  });
};
