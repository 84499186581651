import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@careos/react-ui/AlertDialog';
import { useTranslation } from 'react-i18next';

type RequisitionFormSignedGoBackPromptProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onAction: () => void;
};

export const RequisitionFormSignedGoBackPrompt = ({
  isOpen,
  onOpenChange,
  onAction,
}: RequisitionFormSignedGoBackPromptProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing.go_back_alert_modal',
  });
  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('title')}</AlertDialogTitle>
          <AlertDialogDescription>{t('description')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
          <AlertDialogAction onClick={onAction}>
            {t('continue')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
