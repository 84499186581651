export const genericEn = {
  retry: 'Retry',

  language: {
    language: 'Language',
    english: 'English',
    swedish: 'Swedish',
  },

  navigation: {
    goToStartPage: 'Go to start page',
  },

  user_menu: {
    logout: 'Log out',
  },

  error: {
    title: 'Error message:',
    message: 'An error occurred',
    action: 'Please try again, or contact support if the problem persists.',
  },

  form: {
    firstName: {
      label: 'First name',
    },
    lastName: {
      label: 'Last name',
    },
    email: {
      label: 'Email',
    },
    swedishPersonalNumber: {
      label: 'Swedish personal number',
      placeholder: 'YYYYMMDD-XXXX',
    },
    employer: {
      label: 'Employer',
      placeholder: 'Choose employer',
    },

    optional: 'optional',
    back: 'Back',
    next: 'Next',
  },
};
