import { z } from 'zod';

import {
  BarcodeSchema,
  ContactPointSystemSchema,
  IdentifierSystem,
} from '@abc-labs-ab/ts-events';
import {
  DoaActivityDefinitionIdentifier,
  PethActivityDefinitionIdentifier,
  SamplingKitIdentifierSchema,
} from '@careos/identifiers';
import {
  BaseOrganizationSchema,
  OrderType,
  OrderTypeSchema,
  OrganizationType,
  TestType,
} from '@careos/organization-api-types';
import { nonEmptyString } from '@careos/types';
import { PanelSchema, ReasonSchema } from '../../../common';
import { PersonalIdentityNumberIdentifierSchema } from '../../../common/types/personal-identity-number';

export const HumanNameSchema = z.object({
  text: nonEmptyString,
  family: nonEmptyString.max(40, {
    message: 'Last name should be less than 40 characters.',
  }),
  given: z.array(
    nonEmptyString.max(40, {
      message: 'First name should be less than 40 characters.',
    }),
  ),
});

export const IdentifierPersonSchema = z.union([
  PersonalIdentityNumberIdentifierSchema,
  z.object({
    system: z.literal(IdentifierSystem.PatientReference),
    value: nonEmptyString,
  }),
]);

export const ContactPointEmailSchema = z.object({
  system: z.literal(ContactPointSystemSchema.Enum.email),
  value: z.string().email(),
});
export const ContactPointPhoneSchema = z.object({
  system: z.literal(ContactPointSystemSchema.Enum.phone),
  value: nonEmptyString, // TODO add proper validation
});

export const ContactPointSchema = z.discriminatedUnion('system', [
  ContactPointEmailSchema,
  ContactPointPhoneSchema,
]);

export const PatientSchemaNonCoC = z.object({
  orderType: z.literal(OrderType['non-CoC']),
  name: HumanNameSchema,
  identifier: IdentifierPersonSchema,
  telecom: ContactPointPhoneSchema.optional(),
});

export const PatientSchemaCoC = z.object({
  orderType: z.literal(OrderType.CoC),
  name: HumanNameSchema,
  identifier: IdentifierPersonSchema,
  telecom: ContactPointPhoneSchema,
});

export const PatientSchema = z.discriminatedUnion('orderType', [
  PatientSchemaNonCoC,
  PatientSchemaCoC,
]);

export const PractitionerSchema = z.object({
  name: HumanNameSchema,
  telecom: ContactPointSchema,
});

export const AttesterSchema = z.object({
  identifier: IdentifierPersonSchema,
  name: HumanNameSchema,
});

export const UrineTemperatureSchema = z.object({
  low: z.number(),
  high: z.number(),
  unit: z.enum(['°C', '°F']),
});

export const SpecimenInfoDoaUrineSchema = z.object({
  specimenType: z.literal(DoaActivityDefinitionIdentifier.DOA_URINE_SAMPLING),
  sampledAt: nonEmptyString, // TODO Use proper date validation
  urineTemperature: UrineTemperatureSchema,
});

export const SpecimenInfoDoaSalivaSchema = z.object({
  specimenType: z.literal(DoaActivityDefinitionIdentifier.DOA_SALIVA_SAMPLING),
  sampledAt: nonEmptyString,
  samplingKit: SamplingKitIdentifierSchema,
});

export const SpecimenInfoDoaBloodSchema = z.object({
  specimenType: z.literal(DoaActivityDefinitionIdentifier.DOA_BLOOD_SAMPLING),
  sampledAt: nonEmptyString,
  samplingKit: SamplingKitIdentifierSchema,
});

export const SpecimenInfoPethSchema = z.object({
  specimenType: z.literal(
    PethActivityDefinitionIdentifier.PETH_DRIED_BLOOD_SPOTS,
  ),
  sampledAt: nonEmptyString,
  samplingKit: SamplingKitIdentifierSchema,
});

export const SpecimenInfoDoaSchema = z.discriminatedUnion('specimenType', [
  SpecimenInfoDoaUrineSchema,
  SpecimenInfoDoaSalivaSchema,
  SpecimenInfoDoaBloodSchema,
]);

export const SpecimenInfoSchema = z.discriminatedUnion('specimenType', [
  SpecimenInfoDoaSalivaSchema,
  SpecimenInfoDoaUrineSchema,
  SpecimenInfoDoaBloodSchema,
  SpecimenInfoPethSchema,
]);

export const SpecimenInfoWithSamplingKitSchema = z.union([
  SpecimenInfoDoaSalivaSchema,
  SpecimenInfoDoaBloodSchema,
  SpecimenInfoPethSchema,
]);

export const isSpecimenInfoWithSamplingKit = (
  specimenInfo: SpecimenInfo,
): specimenInfo is SpecimenInfoWithSamplingKit =>
  SpecimenInfoWithSamplingKitSchema.safeParse(specimenInfo).success;

export const SubdivisionSchema = z.object({
  organizationId: nonEmptyString,
  display: nonEmptyString,
});

export const SamplingLocationWorkplaceSchema = BaseOrganizationSchema.extend({
  kind: z.literal(OrganizationType.workplace),
  subdivision: SubdivisionSchema.optional(),
  subcontractor: nonEmptyString.optional(),
});

export const SamplingLocationTreatmentCenterSchema =
  BaseOrganizationSchema.extend({
    kind: z.literal(OrganizationType.treatment_center),
  });

export const SamplingLocationSchema = z.object({
  samplingLocation: z.discriminatedUnion('kind', [
    SamplingLocationWorkplaceSchema,
    SamplingLocationTreatmentCenterSchema,
  ]),
});

export const DoaSpecificSchema = z.object({
  isChiralOrdered: z.boolean(),
  panel: PanelSchema.omit({ panelSubstanceObservations: true }),
  specimenInfo: SpecimenInfoDoaSchema,
  testType: z.literal(TestType.DoA),
});

export const PethSpecificSchema = z.object({
  specimenInfo: SpecimenInfoPethSchema,
  testType: z.literal(TestType.PEth),
});

export const DoaPethDiscriminatedUnionSchema = z.discriminatedUnion(
  'testType',
  [DoaSpecificSchema, PethSpecificSchema],
);

export const GenerateRequisitionRequestDtoBaseSchema = z
  .object({
    orderType: OrderTypeSchema,
    barcodeValue: BarcodeSchema.shape.value,
    patient: PatientSchema,
    practitioner: PractitionerSchema,
    reason: ReasonSchema,
    comment: nonEmptyString.optional(),
    attester: AttesterSchema.optional(),
    samplingSessionId: nonEmptyString.optional(),
  })
  .merge(SamplingLocationSchema);

export const GenerateRequisitionRequestDoaDtoSchema =
  GenerateRequisitionRequestDtoBaseSchema.merge(DoaSpecificSchema);

export const GenerateRequisitionRequestPethDtoSchema =
  GenerateRequisitionRequestDtoBaseSchema.merge(PethSpecificSchema);

export const GenerateRequisitionRequestDtoBaseWithTestTypeInfo =
  GenerateRequisitionRequestDtoBaseSchema.and(
    DoaPethDiscriminatedUnionSchema,
  ).refine(
    (val) =>
      val.samplingLocation.kind === OrganizationType.treatment_center &&
      val.orderType !== 'non-CoC'
        ? false
        : true,
    {
      message: `A sampling location of the kind ${OrganizationType.treatment_center} can only exist with orderType non-CoC`,
    },
  );

export type Attester = z.infer<typeof AttesterSchema>;
export type Patient = z.infer<typeof PatientSchema>;

export type DoaSpecificRequisitionRequest = z.infer<typeof DoaSpecificSchema>;
export type PethSpecificRequisitionRequest = z.infer<typeof PethSpecificSchema>;

export type Practitioner = z.infer<typeof PractitionerSchema>;
export type UrineTemperature = z.infer<typeof UrineTemperatureSchema>;
export type SpecimenInfoDoaUrine = z.infer<typeof SpecimenInfoDoaUrineSchema>;
export type SpecimenInfoPeth = z.infer<typeof SpecimenInfoPethSchema>;
export type SpecimenInfoDoa = z.infer<typeof SpecimenInfoDoaSchema>;
export type SpecimenInfoWithSamplingKit = z.infer<
  typeof SpecimenInfoWithSamplingKitSchema
>;
export type SpecimenInfo = z.infer<typeof SpecimenInfoSchema>;

export type Subdivision = z.infer<typeof SubdivisionSchema>;
export type Employer = z.infer<typeof SamplingLocationWorkplaceSchema>;
export type SamplingLocation = z.infer<typeof SamplingLocationSchema>;
export type TreatmentCenter = z.infer<
  typeof SamplingLocationTreatmentCenterSchema
>;

export type HumanName = z.infer<typeof HumanNameSchema>;
export type IdentifierPerson = z.infer<typeof IdentifierPersonSchema>;

export type ContactPoint = z.infer<typeof ContactPointSchema>;
export type GenerateRequisitionRequestDtoBase = z.infer<
  typeof GenerateRequisitionRequestDtoBaseSchema
>;

export type GenerateRequisitionRequestDoaDto = z.infer<
  typeof GenerateRequisitionRequestDoaDtoSchema
>;
export type GenerateRequisitionRequestPethDto = z.infer<
  typeof GenerateRequisitionRequestPethDtoSchema
>;
export type GenerateReqTodoBase = z.infer<
  typeof GenerateRequisitionRequestDtoBaseWithTestTypeInfo
>;

export const GenerateRequisitionRequestDtoSchema =
  GenerateRequisitionRequestDtoBaseWithTestTypeInfo.and(
    z.object({ locale: z.union([z.literal('sv-SE'), z.literal('en-US')]) }),
  );

export type GenerateRequisitionRequestDto = z.infer<
  typeof GenerateRequisitionRequestDtoSchema
>;
