import { useAuth0 } from '@auth0/auth0-react';
import type { Auth0CareosUser } from '@careos/react-ui/AuthenticatedComponent';
import { AuthProvider } from '@careos/react-ui/AuthProvider';
import { ErrorBoundary, ErrorFallback } from '@careos/react-ui/ErrorBoundary';
import { BackgroundLayout } from '@careos/react-ui/Layouts';
import { Spinner } from '@careos/react-ui/Spinner';
import { ThemeProvider } from '@careos/react-ui/ThemeProvider';
import { initializeSentry } from '@careos/react-ui/utils';
import { type Locale } from '@careos/types';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { useCollectionStore } from '@/features/order/form/stores/collection-store';
import { createHttpClient } from '@/lib/http-client';
import { queryClient } from '@/lib/react-query';

import { routeTree } from './routeTree.gen';

import '../i18n';

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
    queryClient,
    collectionStore: undefined!,
    locale: undefined!,
  },
  defaultPendingComponent: () => (
    <div className="flex h-96 items-center justify-center">
      <Spinner size="lg" />
    </div>
  ),
  defaultErrorComponent: ({ error, reset, info }) => (
    <ErrorFallback
      error={error}
      resetError={reset}
      componentStack={info?.componentStack}
    />
  ),
  defaultNotFoundComponent: NotFoundComponent,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

initializeSentry(router);

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function NotFoundComponent() {
  const { t } = useTranslation('translation');
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img
        src="/abclabs.svg"
        alt="abclogo"
        className="m-6 h-36 rounded-md p-4"
      />
      <p className="text-4xl">{t('nav.not_found')}</p>
    </div>
  );
}

function InnerApp() {
  const auth = useAuth0<Auth0CareosUser>();
  const collectionStore = useCollectionStore();
  const { i18n } = useTranslation();
  const locale: Locale = i18n.language === 'en' ? 'en-US' : 'sv-SE';
  const { loginWithRedirect, getAccessTokenSilently } = auth;

  useEffect(() => {
    createHttpClient({ loginWithRedirect, getAccessTokenSilently });
  }, [loginWithRedirect, getAccessTokenSilently]);

  // INFO: Make sure auth context is loaded before injecting it into the router context
  if (auth.isLoading) {
    return <BackgroundLayout />;
  }

  return (
    <BackgroundLayout>
      <RouterProvider
        router={router}
        context={{ auth, collectionStore, locale }}
      />
    </BackgroundLayout>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider>
            <InnerApp />
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
