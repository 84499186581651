/**
 * Supported languages
 */
export const languageMapping = {
  en: 'english',
  sv: 'swedish',
} as const;

export const supportedLanguages = ['sv', 'en'] as const;
export type SupportedLanguage = keyof typeof languageMapping;
