import { z } from 'zod';

const BankIdNativeUserSchema = z.object({
  personalNumber: z.string(),
  name: z.string(),
  givenName: z.string(),
  surname: z.string(),
});

const BankIdNativeDeviceSchema = z.object({
  ipAddress: z.string(),
  uhi: z.string(),
});

export const BankIdNativeCompletionDataSchema = z.object({
  user: BankIdNativeUserSchema,
  device: BankIdNativeDeviceSchema,
  // Information about extra verifications that were part of the transaction.
  // mrtd: Indicate if there was a check of the mrtd (machine readable travel document). Boolean. True if the mrtd check was performed.
  stepUp: z.boolean(),
  // The date is in the format YYYY-MM-DD / ISO 8601
  // with UTC timezone offset. This is for when the
  // user got their BankID from their bank.
  bankIdIssueDate: z.string(),
  // Base64 encoded XML.
  signature: z.string(),
  /*
   * SHA-1 hash over the base 64 XML signature encoded as UTF-8.
   * 12 random bytes is added after the hash.
   * The nonce is 32 bytes (20 + 12).
   */
  ocspResponse: z.string(),
});

export type BankIdNativeCompletionData = z.infer<
  typeof BankIdNativeCompletionDataSchema
>;
