import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api';
import type { QueryConfig } from '@/lib/react-query';

export const USE_SESSIONS_QUERY_KEY = 'sampling-sessions';

export const getSessionsQueryOptions = () => {
  return queryOptions({
    queryKey: [USE_SESSIONS_QUERY_KEY],
    queryFn: () => api.samplingSessions.get(),
  });
};

export const useSamplingSessions = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getSessionsQueryOptions>;
} = {}) => {
  return useQuery({
    ...getSessionsQueryOptions(),
    ...queryConfig,
  });
};
