import { useAuth0 } from '@auth0/auth0-react';
import type { ReactNode } from '@tanstack/react-router';
import { createContext, useContext } from 'react';
import { Auth0CareosUserSchema, type Auth0CareosUser } from './types';

export const UserContext = createContext<Auth0CareosUser | null>(null);

export const useUserContext = () => {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }

  return { user };
};

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { user: auth0User } = useAuth0();

  if (auth0User === undefined) {
    throw new Error('auth0 user is undefined');
  }
  const user = Auth0CareosUserSchema.parse(auth0User);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
